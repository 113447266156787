import 'regenerator-runtime/runtime'

/* eslint-disable no-console */
/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import googleTagManager from '@analytics/google-tag-manager'
import { ThemeProvider } from '@emotion/react'
import Analytics from 'analytics'
import Head from 'next/head'
import PropTypes from 'prop-types'

import { DrawerProvider } from '@hmn/coolinarika-web-core/context/Drawer'
import { MediaContextProvider } from '@hmn/coolinarika-web-core/controllers'
import { AdsProvider } from '@hmn/coolinarika-web-core/controllers/AdsProvider'
import { AnalyticsContextProvider } from '@hmn/coolinarika-web-core/controllers/AnalyticsContext'
import { NotificationsContextProvider } from '@hmn/coolinarika-web-core/controllers/NotificationsContext'
import { SettingsProvider } from '@hmn/coolinarika-web-core/controllers/SettingsProvider'
import { UserContextProvider } from '@hmn/coolinarika-web-core/controllers/UserContext'
import { WindowContextProvider } from '@hmn/coolinarika-web-core/controllers/WindowContext'
import { globalSettings } from '@hmn/coolinarika-web-core/settings'
import { storeWrapper } from '@hmn/coolinarika-web-core/store'

import { NoticeAlt } from '@hmn/coolinarika-web-ui/components'
import { GlobalStyles } from '@hmn/coolinarika-web-ui/components/GlobalStyles'
import { DefaultSeo } from '@hmn/coolinarika-web-ui/components/Seo/components'
import { TopLine } from '@hmn/coolinarika-web-ui/components/TopLine'
import { themes } from '@hmn/coolinarika-web-ui/theme'

import dataProvider from '@hmn/data-provider'
import * as PodravkaClient from '@hmn/podravkaio'

import { Motion } from '@web/components'
import { BaseLayout } from '@web/layouts'
import useKvikiConsent from '@web/utils/kviki-consent/useKvikiConsent'

import 'kviki-consent/kviki-consent.css'
import '@web/utils/kviki-consent/kviki-consent.css'
import { createSeoConfig } from '../next-seo.config'

const { coolinarika } = themes

const isDev = process.env.NODE_ENV !== 'production'
const { apiBaseUrl } = globalSettings
const webRoot = process.env.NEXT_PUBLIC_WEB_ROOT
const siteName = process.env.NEXT_PUBLIC_WEB_SEO_GLOBAL_TITLE
const dfpNetworkId = process.env.NEXT_PUBLIC_ADS_DFP_NETWORK_ID
const webUrl = `${isDev ? 'http' : 'https'}://${webRoot}`

if (process.browser && isDev) {
    // expose dataProvider in development
    // for easier debugging
    window.dataProvider = dataProvider
}

const CoolinarikaWebApp = ({ Component, err, router, pageProps }) => {
    useKvikiConsent(!Component?.layout || Component?.layout !== 'embed')

    const queryClientRef = useRef()
    const analyticsInstanceRef = useRef()

    const [history, setHistory] = useState({ previous: null, current: router.asPath })

    useEffect(() => {
        setHistory(oldHistory => ({ ...oldHistory, previous: oldHistory.current, current: router.asPath }))
    }, [router.asPath])

    // To prevent accidental cache sharing between users.
    if (!queryClientRef.current) {
        queryClientRef.current = new QueryClient()
    }

    useEffect(() => {
        if (!analyticsInstanceRef.current) {
            analyticsInstanceRef.current = Analytics({
                app: 'coolinarika',
                plugins: [
                    googleTagManager({
                        containerId: 'GTM-PBHQCL'
                    }),
                    {
                        name: 'logger',
                        page: ({ payload } = {}) => {
                            console.debug('[GTM Debug] Page view', payload)
                        },
                        track: ({ payload } = {}) => {
                            console.debug('[GTM Debug] Track', payload)
                        }
                    }
                ]
            })
        }
    }, [])

    useEffect(() => {
        if (
            !PodravkaClient.ApiClient.instance.basePath ||
            PodravkaClient.ApiClient.instance.basePath.includes('docs')
        ) {
            // Initiate dataProvider for client side based requests, no token required
            dataProvider.init({
                baseUrl: `${apiBaseUrl}/podravkaio`,
                language: 'hr'
            })
        }
    })

    if (Component?.layout && Component?.layout === 'embed') {
        return (
            <SettingsProvider>
                <QueryClientProvider client={queryClientRef.current}>
                    <Hydrate state={pageProps?.dehydratedState}>
                        <ThemeProvider theme={coolinarika}>
                            <AnalyticsContextProvider instance={analyticsInstanceRef.current}>
                                <DrawerProvider>
                                    <DefaultSeo
                                        createSeoConfig={createSeoConfig}
                                        configOptions={{ webUrl, siteName, isHomepage: router?.pathname === '/' }}
                                    />
                                    <Head>
                                        {/* Use minimum-scale=1 to enable GPU rasterization */}
                                        <meta
                                            name="viewport"
                                            content="minimum-scale=1,
                                            initial-scale=1,
                                            width=device-width,
                                            shrink-to-fit=no"
                                            key="viewport"
                                        />
                                    </Head>
                                    <GlobalStyles />
                                    <WindowContextProvider>
                                        {/* Workaround for https://github.com/vercel/next.js/issues/8592  */}
                                        <Component {...pageProps} key={router.route} err={err} />
                                    </WindowContextProvider>
                                </DrawerProvider>
                            </AnalyticsContextProvider>
                        </ThemeProvider>
                    </Hydrate>
                </QueryClientProvider>
            </SettingsProvider>
        )
    }

    return (
        <SettingsProvider>
            <QueryClientProvider client={queryClientRef.current}>
                <Hydrate state={pageProps?.dehydratedState}>
                    <MediaContextProvider disableDynamicMediaQueries>
                        <UserContextProvider session={pageProps?.session}>
                            <ThemeProvider theme={coolinarika}>
                                <AnalyticsContextProvider instance={analyticsInstanceRef.current}>
                                    <DrawerProvider>
                                        <AdsProvider dfpNetworkId={dfpNetworkId?.value || dfpNetworkId}>
                                            <DefaultSeo
                                                createSeoConfig={createSeoConfig}
                                                configOptions={{
                                                    webUrl,
                                                    siteName,
                                                    isHomepage: router?.pathname === '/'
                                                }}
                                            />
                                            <Head>
                                                {/* Use minimum-scale=1 to enable GPU rasterization */}
                                                <meta
                                                    name="viewport"
                                                    content="minimum-scale=1,
                                                    initial-scale=1,
                                                    width=device-width,
                                                    shrink-to-fit=no"
                                                    key="viewport"
                                                />
                                            </Head>
                                            <GlobalStyles />
                                            <TopLine layout={Component?.layout} />
                                            <WindowContextProvider>
                                                <NotificationsContextProvider>
                                                    <BaseLayout layout={Component?.layout} history={history}>
                                                        <Motion.Switch>
                                                            <Component {...pageProps} key={router.route} err={err} />
                                                        </Motion.Switch>
                                                    </BaseLayout>
                                                </NotificationsContextProvider>
                                            </WindowContextProvider>
                                            <NoticeAlt
                                                lead="Redovno održavanje je u tijeku.  "
                                                leadOne="Moguće su tehničke poteškoće.  "
                                                leadTwo="Hvala na strpljenju, brzo smo natrag. :)"
                                            />
                                        </AdsProvider>
                                    </DrawerProvider>
                                </AnalyticsContextProvider>
                            </ThemeProvider>
                        </UserContextProvider>
                    </MediaContextProvider>
                </Hydrate>
                {isDev && <ReactQueryDevtools position="top-right" />}
            </QueryClientProvider>
        </SettingsProvider>
    )
}

CoolinarikaWebApp.propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.shape({
        dehydratedState: PropTypes.shape({}),
        session: PropTypes.shape({})
    }),
    router: PropTypes.shape({
        route: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
        asPath: PropTypes.string.isRequired
    }).isRequired,
    err: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
}

CoolinarikaWebApp.defaultProps = {
    pageProps: undefined,
    err: undefined
}

export const reportWebVitals = metric => {
    if (!isDev) {
        switch (metric.name) {
            case 'Next.js-hydration':
                /* eslint-disable no-console */
                console.info('[coolinarika-web][performance][hydration]', metric.value)
                break
            case 'Next.js-route-change-to-render':
                console.info('[coolinarika-web][performance][route-change-to-render]', metric.value)
                break
            case 'Next.js-render':
                console.info('[coolinarika-web][performance][render]', metric.value)
                break
            case 'TTFB': // Time To First Byte
                console.info('[coolinarika-web][performance][ttfb]', metric.value)
                break
            case 'CLS': // Cumulative Layout Shift
                console.info('[coolinarika-web][performance][cls]', metric.value)
                break
            case 'FCP': // First Contentful Paint
                console.info('[coolinarika-web][performance][fcp]', metric.value)
                /* eslint-enable no-console */
                break
            default:
                break
        }
    }
}

export default storeWrapper.withRedux(CoolinarikaWebApp)
