import { toRem, toRems } from '../../../../helpers/theme'

const Style = ({ theme, isShowing }) => {
    const config = {
        mobile: {
            card: {
                padding: toRems([10, 40, 15, 15]),
                maxWidth: toRem(450)
            },
            content: {
                direction: 'column',
                paddingBottom: 0,
                lead: toRem(14)
            },
            button: {
                marginTop: toRem(10)
            }
        },
        desktop: {
            minHeight: '250px',
            card: {
                padding: toRems([15, 30, 25, 30]),
                maxWidth: toRem(450)
            },
            content: {
                direction: 'column',
                width: '75%',
                paddingBottom: 0,
                lead: toRem(16)
            },
            button: {
                marginTop: toRem(12)
            }
        }
    }

    const cardTypeConfig = {
        size: {
            mobile: {
                card: {},
                content: {}
            },
            desktop: {
                card: {
                    backgroundImage: 'url(/images/placeholder/placeholder-user-profile-cta-rotated.png)',
                    backgroundPosition: 'left center',
                    backgroundSize: 'contain'
                },
                content: {
                    marginLeft: 'auto'
                }
            }
        }
    }

    return {
        display: 'none',
        position: 'fixed',
        bottom: toRem(90),
        left: toRem(30),
        right: toRem(30),
        maxWidth: config.mobile?.card?.maxWidth,
        padding: config.mobile?.card?.padding,
        backgroundImage: cardTypeConfig.size.mobile?.card?.backgroundImage,
        backgroundColor: theme.colors.card?.notice.background,
        backgroundPosition: cardTypeConfig.size.mobile?.card?.backgroundPosition,
        backgroundSize: cardTypeConfig.size.mobile?.card?.backgroundSize,
        backgroundRepeat: 'no-repeat',
        ...(isShowing && {
            display: 'block'
        }),
        borderRadius: theme.global.borderRadius.medium,
        zIndex: theme.zIndex.emperor,
        [theme.breakpoints.up('md')]: {
            maxWidth: config.desktop?.card?.maxWidth,
            padding: config.desktop?.card?.padding,
            left: 'auto',
            backgroundImage: cardTypeConfig.size.desktop?.card?.backgroundImage,
            backgroundPosition: cardTypeConfig.size.desktop?.card?.backgroundPosition,
            backgroundSize: cardTypeConfig.size.desktop?.card?.backgroundSize
        },
        [theme.breakpoints.up('lg')]: {
            left: 'auto',
            bottom: toRem(30),
            right: toRem(95)
        },
        '.card_content': {
            display: 'flex',
            flexDirection: config.mobile?.content?.direction,
            width: config.mobile?.content?.width,
            height: '100%',
            paddingBottom: config.mobile?.content?.paddingBottom,
            textAlign: config.mobile?.content?.textAlign,
            [theme.breakpoints.up('md')]: {
                flexDirection: config.desktop?.content?.direction,
                width: config.desktop?.content?.width,
                marginLeft: cardTypeConfig.size.desktop?.content?.marginLeft,
                paddingBottom: config.desktop?.content?.paddingBottom,
                textAlign: config.desktop?.content?.textAlign
            },
            color: theme.colors.informationCard.text
        },
        '.cardContent_text': {
            fontSize: config.mobile?.content?.lead,
            minWidth: '28rem',
            [theme.breakpoints.up('md')]: {
                fontSize: config.desktop?.content?.lead
            }
        },
        '.cardContent_buttonContainer': {
            marginTop: config.mobile?.button?.marginTop,
            [theme.breakpoints.up('md')]: {
                marginTop: config.desktop?.button?.marginTop
            }
        },
        '& .noticeClose_btn': {
            position: 'absolute',
            right: toRem(10),
            top: toRem(10),
            color: theme.colors.white,
            background: '#3BA860',
            [theme.breakpoints.down('sm')]: {
                padding: toRem(4)
            }
        }
    }
}

export default Style
